:root {
  --color-text: #ffffff;
  --color-bg: #060606;
  --color-bg-accent: #111111;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 35);
  --duration: 60s;
}

.logoWall-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: 0 auto;
  max-width: 100%;
  color: var(--color-text);
  background-color: var(--color-bg);
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.marquee {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  justify-content: flex-start;
  width: 100%;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-size: cover;
  mask-repeat: no-repeat;
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  animation: scroll-x var(--duration) linear infinite;
}

.marquee__group {
  min-width: 100%;
}

@keyframes scroll-x {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.paused .marquee__group {
  animation-play-state: paused !important;
}

.marquee__group span {
  width: var(--size);
  background: var(--color-bg-accent);
  padding: 2px 2px;
  border-radius: 0.5rem;
  object-fit: contain;
}

/*Text Gradient*/
.animated-gradient-text {
  position: relative;
  margin: 0 auto;
  display: flex;
  max-width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
  /* 20px */
  font-weight: 500;
  backdrop-filter: blur(10px);
  transition: box-shadow 0.5s ease-out;
  overflow: hidden;
  cursor: pointer;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 300% 100%;
  animation: gradient linear infinite;
  border-radius: inherit;
  z-index: 0;
  pointer-events: none;
}

.gradient-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: inherit;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #060606;
  z-index: -1;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.text-content {
  display: inline-block;
  position: relative;
  z-index: 2;
  background-size: 300% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradient linear infinite;
}